import {
  Box,
  ContentBlock,
  Section,
  Stack,
  Text,
  Button,
} from "@dangerfarms/ui-system";
import {
  Page,
  CaseStudyGrid,
  ProcessSection,
  ServicesAccordion,
} from "@dangerfarms/website-core";
import { graphql, Link } from "gatsby";
import React from "react";
import { PrismicText } from "@dangerfarms/prismic";

const getServicesAccordionData = data =>
  data.filter(item => item.type === "accordion");

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const Approach = ({ data }) => {
  const prismic = data.prismic.allApproach_pages.edges[0].node;
  const sections = prismic.body
    .filter(item => item.type === "section")
    .map(item => item.primary);

  const process = prismic.body.filter(item => item.type === "card_item")[0];

  const relatedCaseStudies = prismic.case_study_showcase
    .map(item => item.case_study)
    .filter(c => c !== null);

  return (
    <Page description={prismic.description} title="Approach">
      <Box paddingX="gutter" paddingY={8}>
        <ContentBlock>
          <Stack>
            <PrismicText
              as="h1"
              richText={prismic.heading}
              variant="epic"
              withHighlights
            />
            <PrismicText
              maxWidth={`${prismic.lede_width}em`}
              richText={prismic.lede}
              variant="lede"
              withHighlights
            />
          </Stack>
        </ContentBlock>
      </Box>

      <ProcessSection process={process} />

      {sections.map((section, index) => {
        const bgColor = index % 2 === 0 ? "bg" : "barelyGrey";

        return (
          <Section key={section.title[0].text} backgroundColor={bgColor}>
            <Stack space={2}>
              <Stack>
                <PrismicText
                  maxWidth={`${section.title_width}em`}
                  richText={section.title}
                  variant="heading"
                  withHighlights
                />
                <PrismicText
                  maxWidth={`${prismic.lede_width}em`}
                  richText={section.content}
                  variant="lede"
                  withHighlights
                />
              </Stack>
              {section.title[0].text === "Services" && (
                <ServicesAccordion
                  data={getServicesAccordionData(prismic.body)}
                />
              )}
            </Stack>
          </Section>
        );
      })}

      <Section backgroundColor="paleGrey">
        <Stack align="stretch" space={3}>
          <Box alignItems="center" boxDisplay={["block", "flex"]}>
            <Text variant="heading">Latest work</Text>
            <Box flex={1} />
            <Box boxDisplay={["none", "block"]}>
              <Button as={Link} to="/case-studies">
                View all case studies
              </Button>
            </Box>
          </Box>
          <CaseStudyGrid caseStudies={relatedCaseStudies} />
        </Stack>
      </Section>
    </Page>
  );
};
/* eslint-enable */

export const query = graphql`
  query approachQuery {
    prismic {
      allApproach_pages {
        edges {
          node {
            description
            heading
            lede
            lede_width
            body {
              ... on PRISMIC_Approach_pageBodySection {
                type
                label
                primary {
                  content
                  content_width
                  title
                  title_width
                }
              }
              ... on PRISMIC_Approach_pageBodyCard_item {
                type
                label
                fields {
                  card_image
                  card_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  card_text
                  card_title
                }
                primary {
                  lede1
                  title
                }
              }
              ... on PRISMIC_Approach_pageBodyAccordion {
                type
                label
                fields {
                  image
                  image_caption
                }
                primary {
                  content
                  title
                }
              }
            }
            case_study_showcase {
              case_study {
                ... on PRISMIC_Case_study {
                  ...CaseStudy
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        baseUrl
        description
        logo
        title
      }
    }
  }
`;

export default Approach;
